import { css } from "@emotion/core"

import theme, { accent } from "../themes/light"

export default css`
  html {
    height: 100%;
  }
  body {
    height: 100%;
    ${theme}
  }
  #___gatsby {
    height: 100%;
  }
  #gatsby-focus-wrapper {
    height: 100%;
  }
`
export { accent }
