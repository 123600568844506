import React from "react"
import { css } from "@emotion/core"
import { graphql, Link, useStaticQuery } from "gatsby"

import { rhythm } from "../../utils/typography"
import src from "../../images/divider1.svg"
import Divider from "./divider"
//import Colophon from "./colophon"

/**
 *     <Divider />

 */
export default () => {
  const { site } = useStaticQuery(query)
  return (
    <footer
      css={css`
        padding: 0 ${rhythm(1)};
        margin-bottom: 1rem;
        .row {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          font-size: 0.75rem;
        }
      `}
    >
      <hr class="divider7" />
    </footer>
  )
}

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        domain
        twitterUsername
      }
    }
  }
`
