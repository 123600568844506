import "yakuhanjp"

export default {
  title: "japanese-riywo",
  baseFontSize: "20px",
  baseLineHeight: 1.7,
  scaleRatio: 1.7,
  googleFonts: [
    {
      name: "Sawarabi+Mincho",
      styles: ["400"],
    },
    {
      name: "Kosugi",
      styles: ["400", "700"],
    },
    {
      name: "M+PLUS+1p",
      styles: ["400"],
    },
    {
      name: "Roboto+Slab",
      styles: ["700"],
    },
    {
      name: "Noto+Serif+JP",
      styles: ["400"],
    },
    {
      name: "Source+Serif+Pro",
      styles: ["400", "700"],
    },
  ],
  headerFontFamily: [
    "YakuHanJP",
    "Source Serif Pro",
    "Sawarabi Mincho",
    "Noto Serif JP",
    "serif",
  ],
  bodyFontFamily: [
    "YakuHanJP",
    "Source Serif Pro",
    "Sawarabi Mincho",
    "Noto Serif JP",
    "serif",
  ],
  //headerGray: 30,
  bodyGray: 30,
  overrideStyles: ({ scale, rhythm }, options, styles) => ({
    html: {
      WebkitFontSmoothing: "antialiased",
      "--rhythm1": rhythm(1),
    },
    "h1,h2,h3,h4,h5,h6": {
      letterSpacing: "0.04em",
      color: "hsla(0,0%,0%,0.7)",
      lineHeight: 1.3,
      marginBottom: rhythm(1.2),
    },
    "h1:not(:first-child),h2:not(:first-child),h3:not(:first-child),h4:not(:first-child),h5:not(:first-child),h6:not(:first-child)": {
      marginTop: rhythm(1.5),
    },
    p: {
      textAlign: "justify",
      wordBreak: "break-all",
    },
    "p:lang(en)": {
      textAlign: "justify",
      wordBreak: "normal",
      lineHeight: "1.6",
    },
    li: {
      marginBottom: "0",
    },
  }),
}
