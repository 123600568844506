import { css } from "@emotion/core"
import { lch } from "chroma-js"

import divider1 from "../images/divider1.svg"
import divider2 from "../images/divider2.svg"
import divider3 from "../images/divider3.svg"
import divider4 from "../images/divider4.svg"
import divider5 from "../images/divider5.svg"
import divider6 from "../images/divider6.svg"
import divider7 from "../images/divider7.svg"
import divider8 from "../images/divider8.svg"
import divider9 from "../images/divider9.svg"
import divider10 from "../images/divider10.svg"
import divider11 from "../images/divider11.svg"
import divider12 from "../images/divider12.svg"
import divider13 from "../images/divider13.svg"
import divider14 from "../images/divider14.svg"
import divider15 from "../images/divider15.svg"
import divider16 from "../images/divider16.svg"

const darkest = lch(12.5, 3, 18 * 15)
const lightest = lch(100.0, 1, 18 * 15)
const count = 8
const steps = darkest.lch().map((v, i) => {
  return (lightest.lch()[i] - v) / (count - 1)
})

function range(to) {
  const result = []
  for (var i = 0; i <= to; i++) {
    result.push(i)
  }
  return result
}

const [base03, base02, base01, base00, base0, base1, base2, base3] = range(
  count - 1
).map(i => {
  return lch([
    darkest.lch()[0] + steps[0] * i,
    darkest.lch()[1] + steps[1] * i,
    darkest.lch()[2] + steps[2] * i,
  ])
})

const mono = {
  base03,
  base02,
  base01,
  base00,
  base0,
  base1,
  base2,
  base3,
}

const accent = {
  //                 輝度,   彩度,     色相
  //            Lightness, Chroma,     Hue
  red: lch(35, 40, 2 * 15),
  orange: lch(55, 45, 4 * 15),
  yellow: lch(60, 50, 6 * 15),
  green: lch(30, 35, 9 * 15),
  cyan: lch(35, 40, 14 * 15),
  blue: lch(30, 40, 18 * 15),
  violet: lch(32, 40, 21 * 15),
  magenta: lch(32, 35, 24 * 15),
}

const divider = css`
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 1rem;
  background-image: url(${divider1});
  margin: 1rem 0;
`

export default css`
  --base03: ${mono.base03.css()};
  --base02: ${mono.base02.css()};
  --base01: ${mono.base01.css()};
  --base00: ${mono.base00.css()};
  --base0: ${mono.base0.css()};
  --base1: ${mono.base1.css()};
  --base2: ${mono.base2.css()};
  --base3: ${mono.base3.css()};
  --red: ${accent.red.css()};
  --orange: ${accent.orange.css()};
  --yellow: ${accent.yellow.css()};
  --green: ${accent.green.css()};
  --cyan: ${accent.cyan.css()};
  --blue: ${accent.blue.css()};
  --violet: ${accent.violet.css()};
  --magenta: ${accent.magenta.css()};

  color: var(--base03);
  background-color: var(--base3);
  *::selection {
    color: var(--base3);
    background-color: var(--base03);
  }
  *:focus {
    outline-color: var(--base01);
    outline-style: solid;
    outline-width: 3px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--base03);
  }

  a:link,
  a:visited,
  a:active {
    text-decoration: none;
    color: inherit;
  }
  a:hover {
    text-decoration: underline;
  }

  .text-base03 {
    color: var(--base03);
  }
  .text-base02 {
    color: var(--base02);
  }
  .text-base01 {
    color: var(--base01);
  }
  .text-base00 {
    color: var(--base00);
  }
  .text-base0 {
    color: var(--base0);
  }
  .text-base1 {
    color: var(--base1);
  }
  .text-base2 {
    color: var(--base2);
  }
  .text-base3 {
    color: var(--base3);
  }
  .text-red {
    color: var(--red);
  }
  .text-orange {
    color: var(--orange);
  }
  .text-yellow {
    color: var(--yellow);
  }
  .text-green {
    color: var(--green);
  }
  .text-cyan {
    color: var(--cyan);
  }
  .text-blue {
    color: var(--blue);
  }
  .text-violet {
    color: var(--violet);
  }
  .text-magenta {
    color: var(--magenta);
  }

  hr.divider {
    ${divider}
  }
  hr.divider1 {
    ${divider}
    background-image: url(${divider1});
  }
  hr.divider2 {
    ${divider}
    background-image: url(${divider2});
  }
  hr.divider3 {
    ${divider}
    background-image: url(${divider3});
  }
  hr.divider4 {
    ${divider}
    background-image: url(${divider4});
  }
  hr.divider5 {
    ${divider}
    background-image: url(${divider5});
  }
  hr.divider6 {
    ${divider}
    background-image: url(${divider6});
  }
  hr.divider7 {
    ${divider}
    height: 0.75rem;
    background-image: url(${divider7});
  }
  hr.divider8 {
    ${divider}
    background-image: url(${divider8});
  }
  hr.divider9 {
    ${divider}
    background-image: url(${divider9});
  }
  hr.divider10 {
    ${divider}
    background-image: url(${divider10});
  }
  hr.divider11 {
    ${divider}
    background-image: url(${divider11});
  }
  hr.divider12 {
    ${divider}
    background-image: url(${divider12});
  }
  hr.divider13 {
    ${divider}
    background-image: url(${divider13});
  }
  hr.divider14 {
    ${divider}
    background-image: url(${divider14});
  }
  hr.divider15 {
    ${divider}
    background-image: url(${divider15});
  }
  hr.divider16 {
    ${divider}
    background-image: url(${divider16});
  }
`

export { mono, accent }
